let d = window.define;
d("@ember-decorators/component/index", function () {
  return esc(require("@ember-decorators/component/index"));
});
d("@ember-decorators/object/index", function () {
  return esc(require("@ember-decorators/object/index"));
});
import "ember-cli-babel/-embroider-implicit-modules.js";
import "@ember-decorators/component/-embroider-implicit-modules.js";
import "@ember-decorators/object/-embroider-implicit-modules.js";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
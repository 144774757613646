let d = window.define;
d("@embroider/macros/es-compat2", function () {
  return esc(require("@embroider/macros/es-compat2"));
});
d("@embroider/macros/runtime", function () {
  return esc(require("@embroider/macros/runtime"));
});
import "ember-cli-babel/-embroider-implicit-modules.js";
import "@embroider/macros/-embroider-implicit-modules.js";
import "@glimmer/tracking/-embroider-implicit-modules.js";
import "ember-cache-primitive-polyfill/-embroider-implicit-modules.js";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
let d = window.define;
d("@glimmer/component/-private/base-component-manager", function () {
  return esc(require("@glimmer/component/-private/base-component-manager"));
});
d("@glimmer/component/-private/component", function () {
  return esc(require("@glimmer/component/-private/component"));
});
d("@glimmer/component/-private/destroyables", function () {
  return esc(require("@glimmer/component/-private/destroyables"));
});
d("@glimmer/component/-private/ember-component-manager", function () {
  return esc(require("@glimmer/component/-private/ember-component-manager"));
});
d("@glimmer/component/-private/owner", function () {
  return esc(require("@glimmer/component/-private/owner"));
});
d("@glimmer/component/index", function () {
  return esc(require("@glimmer/component/index"));
});
d("@glimmer/component/-private/base-component-manager", function () {
  return esc(require("@glimmer/component/-private/base-component-manager"));
});
d("@glimmer/component/-private/component", function () {
  return esc(require("@glimmer/component/-private/component"));
});
d("@glimmer/component/-private/destroyables", function () {
  return esc(require("@glimmer/component/-private/destroyables"));
});
d("@glimmer/component/-private/ember-component-manager", function () {
  return esc(require("@glimmer/component/-private/ember-component-manager"));
});
d("@glimmer/component/-private/owner", function () {
  return esc(require("@glimmer/component/-private/owner"));
});
d("@glimmer/component/index", function () {
  return esc(require("@glimmer/component/index"));
});
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import "@glimmer/component/-embroider-implicit-modules.js";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
let d = window.define;
d("@ember-decorators/utils/-private/class-field-descriptor", function () {
  return esc(require("@ember-decorators/utils/-private/class-field-descriptor"));
});
d("@ember-decorators/utils/collapse-proto", function () {
  return esc(require("@ember-decorators/utils/collapse-proto"));
});
d("@ember-decorators/utils/decorator", function () {
  return esc(require("@ember-decorators/utils/decorator"));
});
import "ember-cli-babel/-embroider-implicit-modules.js";
import "@ember-decorators/utils/-embroider-implicit-modules.js";
import esc from "../../../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";